import React, {ChangeEvent, Component, ComponentType} from "react";
import {sendEmailForReset} from "api";
import {Link} from "react-router-dom";
import ArrowBackSVG from "../../Svg/ArrowBack";
import {ProviderContext} from "notistack";
import {withSnackbar} from "utils/withSnackbar";
import {withMixpanel} from "utils/MixpanelContext";
import "./ResetPassword.scss";
import {OverridedMixpanel} from "mixpanel-browser";
import {SnakbarFunction} from "types/misc";

class ResetPassword extends Component {
  state = {
    userEmail: "",
    userEmailError: false,
    resetEmail: "",
    redirect: false,
  };

  get mixpanel() {
    const {mixpanel} = this.props as {
      mixpanel: OverridedMixpanel;
    };
    return mixpanel;
  }

  get enqueueSnackbar() {
    const {enqueueSnackbar} = this.props as {
      enqueueSnackbar: SnakbarFunction;
    };
    return enqueueSnackbar;
  }

  handleSubmit = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const {userEmail} = this.state;
    if (!userEmail.length) {
      return;
    }
    
    sendEmailForReset(userEmail)
      .finally(() => {
        const mixPayload = {
          userEmail,
        };
        this.mixpanel.track(`request_reset_password`, mixPayload);
        this.setState({
          resetEmail: userEmail,
          redirect: true,
        });
        this.enqueueSnackbar("If an account exists, a password reset email will be sent", {
          variant: "success",
          autoHideDuration: 5000,
        });
      });
  };

  handleEmailInput = (e: ChangeEvent<HTMLInputElement>) => {
    let regExp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regExp.test(String(e.target.value).toLowerCase())) {
      this.setState({
        [[e.target.name] + "Error"]: true,
      });
    }
  };

  render() {
    const {userEmailError, redirect, resetEmail} = this.state;

    return (
      <div className="wrapper__login">
        <Link to="/login">
          <ArrowBackSVG color={"#ffffff"} />
        </Link>
        {redirect ? (
          <form className="login-form" onSubmit={(e) => e.preventDefault()}>
            <p className="login-form__title"></p>
            <h1 className="login-form__header">Reset Your Password</h1>
            <p className="login-form__subtitle" data-testid="reset-password-email-sent-title">
              We have sent a reset password email to <br />
              {resetEmail} <br />
              Please click the reset password link to set your new password.
            </p>
            <p className="login-form__subtitle">
              Didn’t receive the email yet? Please check your spam folder, or
              resend the email.
            </p>
          </form>
        ) : (
          <form
            className="login-form"
            onSubmit={(e) =>
              this.handleSubmit(e as unknown as ChangeEvent<HTMLInputElement>)
            }
            onChange={() => this.setState({userEmailError: false})}
          >
            <p className="login-form__title"></p>
            <p className="login-form__subtitle">
              Don’t worry. <br /> Resetting your password is easy, just tell us
              the email address you registered with Deeded.
            </p>
            <div className="login-form__input">
              <label
                htmlFor="email"
                className="login-form__icon--email"
              ></label>
              <input
                data-testid="reset-password-email"
                name="userEmail"
                type="email"
                className={
                  userEmailError
                    ? "login-form__email input-error"
                    : "login-form__email"
                }
                placeholder="Email"
                onChange={(e) =>
                  this.setState({
                    [e.target.name]: e.target.value,
                  })
                }
                onBlur={this.handleEmailInput}
              />

              <span
                className={
                  userEmailError ? "error" : (null as unknown as undefined)
                }
              >
                invalid email
              </span>
            </div>

            <button
              data-testid="reset-password-send-button"
              type="submit"
              className="login-form__login"
              style={{margin: "0"}}
            >
              Send
            </button>
          </form>
        )}
      </div>
    );
  }
}

export default withMixpanel(
  withSnackbar(ResetPassword as unknown as ComponentType<ProviderContext>),
);
